/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-08-05 16:23:45
 * @LastEditTime: 2022-08-15 15:36:42
 */
import axios from '@/utils/request'
let Api = {
	recruitList: "/recruit/list",
	recruitAdd: "/recruit/",
	recruitFaculty: "/college/list",
	recruitDetail: "/recruit/",
	recruitEdit: "/recruit/",
	recruitChange: "/recruit/switch",
	recruitSearch: "/recruit/inquire",
	recruitApplyList: "/signUpInformation/list",
	applyedMessageSend: "/signUpInformation/notification",
	applyListDownload: "/signUpInformation/export",
	timeSheetCreate: "/recruit/generate",
	volunteerList: "/volunteer/information/list",
	applyedEdit: "/signUpInformation/",
	privateChat: "/siteMsg/",
	listTimesLeader:"/signUpInformation/listTimesLeader",
	signUpInformation:"/signUpInformation/",
}
//查询时间段报名信息
export function listTimesLeader(params) {
	return axios({
		method: 'get',
		url: Api.listTimesLeader,
		params: params
	})
}
//设置领队
export function signUpInformation(id, params) {
	return axios({
		method: 'put',
		url: Api.signUpInformation + id,
		data: params
	})
}
// 招募列表
export function getRecruitList(params) {
	return axios({
		method: 'get',
		url: Api.recruitList,
		params: params
	})
}
// 新建招募
export function addRecruit(params) {
	return axios({
		method: 'post',
		url: Api.recruitAdd,
		data: params
	})
}
// 招募院系
export function recruitFacultyList(params) {
	return axios({
		method: 'get',
		url: Api.recruitFaculty,
		params: params
	})
}
// 招募详情
export function detailRecruit(id, params) {
	return axios({
		method: 'get',
		url: Api.recruitDetail + id,
		params: params
	})
}
// 招募修改
export function editRecruit(id, params) {
	return axios({
		method: 'put',
		url: Api.recruitEdit + id + "?id=" + id,
		data: params
	})
}
// 招募开启/关闭
export function changeRecruit(params) {
	return axios({
		method: 'post',
		url: Api.recruitChange,
		data: params
	})
}
// 招募查询
export function searchRecruit(params) {
	return axios({
		method: 'get',
		url: Api.recruitSearch,
		params: params
	})
}
// 报名信息表
export function listRecruitApply(params) {
	return axios({
		method: 'get',
		url: Api.recruitApplyList,
		params: params
	})
}
// 录取消息发送
export function sendApplyedMessage(params) {
	return axios({
		method: 'post',
		url: Api.applyedMessageSend,
		data: params
	})
}
// 报名信息导出
export function downloadApplyList(params) {
	return axios({
		method: 'get',
		url: Api.applyListDownload,
		params: params,
		responseType: 'arraybuffer',
		observe: 'response',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true
	})
}
// 工时表生成
export function createTimeSheet(params) {
	return axios({
		method: 'post',
		url: Api.timeSheetCreate,
		data: params
	})
}
// 志愿者列表
export function listVolunteer(params) {
	return axios({
		method: 'get',
		url: Api.volunteerList,
		data: params
	})
}
// 报名信息编辑
export function editApplyed(id, params) {
	return axios({
		method: 'put',
		url: Api.applyedEdit + id,
		data: params
	})
}
// 私聊
export function chatPrivate(params) {
	return axios({
		method: 'post',
		url: Api.privateChat,
		data: params
	})
}
