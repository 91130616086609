<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="timeSheet">
		<common-title :title="'生成工时表'" />
		<div class="timeSheet-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input readonly="readonly" class="common-row-ipt" placeholder="请输入" v-model="activityName" />
				</div>
			</div>
			<!-- 志愿者信息 -->
			<div class="">
				<div class="timeSheet-title">志愿者信息</div>
				<div class="timeSheet-table">
					<el-table style="width: 100%" :data="volunteerData" border>
						<el-table-column width="60px" prop="" label="选择">
							<template slot-scope="scope">
								<div class="table-action">
									<el-checkbox @change="volunteerSelect($event, scope)" v-model="scope.row.checked" />
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="姓名">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.name}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="studentId" label="学号">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.studentId}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="workingHours" label="工时数">
							<template slot-scope="scope">
								<div class="table-action">
									<el-input class="table-action-ipt" v-model="scope.row.workingHours"
										placeholder="请输入" />
								</div>
							</template>
						</el-table-column>
					</el-table>
					<!-- <div class="common-pageNation">
						<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
							:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
						</el-pagination>
					</div> -->
				</div>
				<div class="timeSheet-btn flex-item">
					<el-button @click="confirm" type="primary">确定</el-button>
					<el-button @click="cancel">重置</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		createTimeSheet,
		listVolunteer,
		listRecruitApply,
	} from "@/api/recruit";
	export default {
		data() {
			return {
				volunteerData: [],
				activityId: "",
				activityName: "",
				recruitId: "",
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10000,
				volunteerSelected: []
			};
		},
		computed: {},
		mounted() {
			this.activityId = this.$route.query.activityId
			this.activityName = this.$route.query.activityName
			this.recruitId = this.$route.query.recruitId
			this.getVolunteerList()
		},
		methods: {
			volunteerSelect(e, scope) {
				// console.log("volunteerSelect", e, scope)
				scope.row.checked = e
			},
			getVolunteerList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					recruitId: this.recruitId,
					activityId: this.activityId,
					state: 2
				}
				this.volunteerData = [];
				listRecruitApply(params).then((res) => {
					if (res.code == 0) {
						if (res.data && res.data.length > 0) {
							res.data.forEach(item => {
								this.$set(item, "workingHours", 0)
								this.$set(item, "checked", false)
							})
							this.volunteerData = res.data
						} else {
							this.volunteerData = []
						}
					}
				})
			},
			workingHoursCheck(intNum) {
				if(intNum) {
					let regx1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/;
					if (regx1.test(intNum)) {
						console.log("intNum", intNum)
						let pointIndex = intNum.lastIndexOf('.')
						if (pointIndex != -1) {
							let regx2 = /^\d+(\.\d{1,1})?$/
							if (regx2.test(intNum)) {
								let pointNum = intNum.slice(pointIndex + 1, intNum.length)
								// console.log("pointNum", pointIndex, pointNum)
								if ((pointNum * 1) == 5) {
									return true
								} else {
									return false
								}
							} else {
								return false
							}
						} else {
							return true
						}
					} else {
						return false
					}
				} else {
					return false
				}
				
			},
			confirm() {
				this.volunteerSelected = []
				let emptyArr = []
				let filterArr = this.volunteerData.filter(item => item.checked == true)
				emptyArr = emptyArr.concat(filterArr)
				if (emptyArr.length > 0) {
					emptyArr.forEach(item => {
						this.volunteerSelected.push({
							activityId: this.activityId,
							recruitId: this.recruitId,
							name: item.volunteer.name,
							studentId: item.volunteer.studentId,
							volunteerId: item.volunteerId,
							workingHours: item.workingHours
						})
					})
				}
				console.log("confirm", this.volunteerSelected)
				if (this.volunteerSelected.length > 0) {
					let workingHoursRegx = this.volunteerSelected.every(item => {
						return this.workingHoursCheck(item.workingHours + '')
					})
					console.log("workingHoursRegx", workingHoursRegx)
					if (workingHoursRegx) {
						let params = {
							workingHourDTOS: this.volunteerSelected
						}
						createTimeSheet(params).then((res) => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: '工时表生成成功~'
								});
								this.getVolunteerList()
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: '请确保工时数都大于0或者小数为(x.5格式)~'
						});
					}
				} else {
					this.$message({
						type: 'error',
						message: '请选择志愿者~'
					});
				}
			},
			cancel() {
				this.getVolunteerList()
			},
			currentChange(val) {
				this.pageIndex = val;
				this.volunteerData = [];
				this.getVolunteerList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	/deep/.timeSheet-btn .el-button--default {
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	.flex-item {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.timeSheet {
		.timeSheet-content {
			padding: 0 20px 20px 20px;

			.timeSheet-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
			}

			.timeSheet-btn {
				margin-top: 20px;
			}

			.table-action {
				.table-action-ipt {
					width: 120px;
					border: 1px solid #EDF1F7;
					border-radius: 2px;
				}
			}

			.common-row-label {
				height: 38px;
				line-height: 38px;
			}

			.common-row-ipt {
				flex: 1;
			}
		}
	}
</style>
